var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-4"},[_c('CRow',{staticClass:"mb-0"},[_c('CCol',{attrs:{"lg":"2"}},[_c('CInput',{attrs:{"placeholder":"Client billing no."},model:{value:(_vm.client_billing_no),callback:function ($$v) {_vm.client_billing_no=$$v},expression:"client_billing_no"}})],1),_c('CCol',{attrs:{"lg":"2"}},[_c('v-select',{attrs:{"label":"customer_name","options":_vm.customerList.data,"reduce":function (item) { return item.id; },"placeholder":"Customer"},model:{value:(_vm.customer_id),callback:function ($$v) {_vm.customer_id=$$v},expression:"customer_id"}})],1),_c('CCol',{attrs:{"lg":"2"}},[_c('v-select',{attrs:{"label":"setting_name","options":_vm.originList.data,"reduce":function (item) { return item.id; },"placeholder":"Origin"},model:{value:(_vm.origin_id),callback:function ($$v) {_vm.origin_id=$$v},expression:"origin_id"}})],1),_c('CCol',{attrs:{"lg":"2"}},[(_vm.is_marvel())?_c('v-select',{attrs:{"label":"setting_name","options":[
            {setting_name: 'Billed', id: 'billed'},
            {setting_name: 'Unbilled', id: 'unbilled'},
            {setting_name: 'Paid', id: 'PAID'},
            {setting_name: 'Partially Paid', id: 'PARTIALLY PAID'},
            {setting_name: 'Cancelled', id: 'cancelled'}
          ],"reduce":function (item) { return item.id; },"placeholder":"Status"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}}):_c('v-select',{attrs:{"label":"setting_name","options":[
            {setting_name: 'Billed', id: 'billed'},
            {setting_name: 'Unbilled', id: 'unbilled'},
            {setting_name: 'Cancelled', id: 'cancelled'}
          ],"reduce":function (item) { return item.id; },"placeholder":"Status"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),_c('CCol',{attrs:{"lg":"2"}},[_c('CInput',{attrs:{"placeholder":"Ref no."},model:{value:(_vm.ref_no),callback:function ($$v) {_vm.ref_no=$$v},expression:"ref_no"}})],1),_c('CCol',{staticClass:"float-right",attrs:{"lg":"2"}},[_c('CButton',{attrs:{"block":"","color":"info"},on:{"click":_vm.search}},[_c('i',{staticClass:"icon-magnifier"}),_vm._v(" Search")])],1)],1),_c('CRow',[_c('CCol',{attrs:{"lg":"2"}},[_c('Datepicker',{attrs:{"input-class":"form-control","placeholder":"Date created from"},model:{value:(_vm.created_at_from),callback:function ($$v) {_vm.created_at_from=$$v},expression:"created_at_from"}})],1),_c('CCol',{attrs:{"lg":"2"}},[_c('Datepicker',{attrs:{"input-class":"form-control","placeholder":"Date created to"},model:{value:(_vm.created_at_to),callback:function ($$v) {_vm.created_at_to=$$v},expression:"created_at_to"}})],1),_c('CCol',{attrs:{"lg":"2"}},[_c('Datepicker',{attrs:{"input-class":"form-control","placeholder":"Delivery date from"},model:{value:(_vm.delivery_date_from),callback:function ($$v) {_vm.delivery_date_from=$$v},expression:"delivery_date_from"}})],1),_c('CCol',{attrs:{"lg":"2"}},[_c('Datepicker',{attrs:{"input-class":"form-control","placeholder":"Delivery date to"},model:{value:(_vm.delivery_date_to),callback:function ($$v) {_vm.delivery_date_to=$$v},expression:"delivery_date_to"}})],1),_c('CCol',{attrs:{"lg":"2"}},[_c('v-select',{attrs:{"label":"setting_name","options":_vm.depotList.data,"reduce":function (item) { return item.id; },"placeholder":"Depot"},model:{value:(_vm.depot_id),callback:function ($$v) {_vm.depot_id=$$v},expression:"depot_id"}})],1),_c('CCol',{staticClass:"float-right",attrs:{"lg":"2"}},[_c('CButton',{attrs:{"block":"","color":"info"},on:{"click":_vm.clearSearch}},[_vm._v("Clear Search")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }