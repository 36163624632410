<template>
  <div class="mb-4">
      <CRow class="mb-0">
        <CCol lg="2">
          <CInput v-model="client_billing_no" placeholder="Client billing no."/>
        </CCol>
        <CCol lg="2">
          <v-select 
            label="customer_name" 
            :options="customerList.data"
            :reduce="item => item.id"
            v-model="customer_id"
            placeholder="Customer"
          > 
          </v-select>
        </CCol>
        <CCol lg="2">
          <v-select 
            label="setting_name" 
            :options="originList.data"
            :reduce="item => item.id"
            v-model="origin_id"
            placeholder="Origin"
          > 
          </v-select>
        </CCol>
        <CCol lg="2">
          <v-select v-if="is_marvel()"
            label="setting_name" 
            :options="[
              {setting_name: 'Billed', id: 'billed'},
              {setting_name: 'Unbilled', id: 'unbilled'},
              {setting_name: 'Paid', id: 'PAID'},
              {setting_name: 'Partially Paid', id: 'PARTIALLY PAID'},
              {setting_name: 'Cancelled', id: 'cancelled'}
            ]"
            :reduce="item => item.id"
            v-model="status"
            placeholder="Status"
          > 
          </v-select> 
          <v-select v-else
            label="setting_name" 
            :options="[
              {setting_name: 'Billed', id: 'billed'},
              {setting_name: 'Unbilled', id: 'unbilled'},
              {setting_name: 'Cancelled', id: 'cancelled'}
            ]"
            :reduce="item => item.id"
            v-model="status"
            placeholder="Status"
          > 
          </v-select> 
        </CCol> 
        <CCol lg="2">
          <CInput v-model="ref_no" placeholder="Ref no."/>
        </CCol>
        <CCol lg="2" class="float-right">
          <CButton block color="info" @click="search"><i class="icon-magnifier"></i> Search</CButton>
        </CCol>
      </CRow>
      <CRow>
        <CCol lg="2">
          <Datepicker v-model="created_at_from" input-class="form-control" placeholder="Date created from" />
        </CCol>
        <CCol lg="2">
          <Datepicker v-model="created_at_to" input-class="form-control" placeholder="Date created to" />
        </CCol>
        <CCol lg="2">
          <Datepicker v-model="delivery_date_from" input-class="form-control" placeholder="Delivery date from" />
        </CCol>
        <CCol lg="2">
          <Datepicker v-model="delivery_date_to" input-class="form-control" placeholder="Delivery date to" />
        </CCol>
        <CCol lg="2">
          <v-select 
            label="setting_name" 
            :options="depotList.data"
            :reduce="item => item.id"
            v-model="depot_id"
            placeholder="Depot"
          > 
          </v-select>
        </CCol>
        <CCol lg="2" class="float-right">
          <CButton block @click="clearSearch" color="info">Clear Search</CButton>
        </CCol>
      </CRow>
  </div>
</template>

<script>
  import config from '../../config.js';
  import axios from '../../axios';
  import vSelect from 'vue-select'
  import 'vue-select/dist/vue-select.css';
  import Datetime from 'vuejs-datetimepicker';
  import Datepicker from 'vuejs-datepicker';
    export default {
        name: "BookingTypeSearch",
        data() {
          return {
            client_billing_no: "",
            customer_id: "",
            origin_id: "",
              depot_id: "",
            status: "",
            ref_no: "", 
            created_at_from: "",
            created_at_to: "",
            delivery_date_from: "",
            delivery_date_to: "",
            customerList: {
            	data: []
            },
      			originList: {
      				data: []
      			},
            
            depotList: {data:[]}
          }
          
        },
      mounted() {
        this.getCustomer();
        this.getOrigin();
        this.getDepot();
        console.log(config);
      },
      components: {vSelect, Datetime, Datepicker},
      methods: {
        search() {
          const data = {
            client_billing_no: this.client_billing_no,
            customer_id: this.customer_id,
            origin_id: this.origin_id,
             depot_id: this.depot_id,
            status: this.status,
            ref_no: this.ref_no,
            created_at_from: this.created_at_from,
            created_at_to: this.created_at_to,
            delivery_date_from: this.delivery_date_from,
            delivery_date_to: this.delivery_date_to,
          }
          this.$emit("depot-search-query", data)
        },
        clearSearch() {
          this.client_billing_no = "";
          this.customer_id = "";
          this.origin_id = "";
          this.depot_id = "";
          this.status = "";
          this.ref_no = ""; 
          this.created_at_from = ""; 
          this.created_at_to = ""; 
          this.delivery_date_from = ""; 
          this.delivery_date_to = ""; 
          const data = {
            client_billing_no: this.client_billing_no,
            customer_id: this.customer_id,
            origin_id: this.origin_id,
            depot_id: this.depot_id,
            status: this.status,
            ref_no: this.ref_no,
            created_at_from: this.created_at_from,
            created_at_to: this.created_at_to,
            delivery_date_from: this.delivery_date_from,
            delivery_date_to: this.delivery_date_to,
          }
          this.$emit("depot-search-query", data)
        },

        getCustomer(){
          axios.get(config.api_path+'/reference/customer-list', {
            params: {
              show_all : true
            }
          })
	    	.then(response => {
	    		this.customerList = response.data; 
	    	})
      },

	    getOrigin(){

        axios.get(config.api_path+'/reference/settings-list',{
            params:{
              setting_type:'origin'
            }
              })
        .then(response => {
          this.originList = response.data; 
        })

        },

       getDepot(){

        axios.get(config.api_path+'/reference/settings-list',{
            params:{
              setting_type:'depot'
            }
              })
        .then(response => {
          this.depotList = response.data; 
        })

        },
      }
    }
</script>

<style scoped>

</style>
